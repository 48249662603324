import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Firebase

const firebaseConfig = {
    apiKey: "AIzaSyCyHvNfuRkk6d6UrYlZNz8A7XpcmkpIBUQ",
    authDomain: "elearn-2e113.firebaseapp.com",
    projectId: "elearn-2e113",
    storageBucket: "elearn-2e113.appspot.com",
    messagingSenderId: "42627245524",
    appId: "1:42627245524:web:bd03988a93a460a91ca241",
    measurementId: "G-YS4L735WS4",
};

firebase.initializeApp(firebaseConfig);

// Get a Firestore instance
const db = firebase.firestore();

// Set up our collections
const usersCollection = db.collection("users");
const userModulesCollection = db.collection("usermodules");
const userQuizesCollection = db.collection("userquizes");

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true });

const updateUser = function (firebaseUser) {
    let userId = firebaseUser.uid;

    usersCollection
        .doc(userId)
        .set({
            createdAt: firebaseUser.metadata.createdAt,
            lastLoginAt: firebaseUser.metadata.lastLoginAt,
            displayName: firebaseUser.displayName,
            email: firebaseUser.email,
        })
        .then(() => {
            console.log("user updated");
        });
};

export {
    db,
    usersCollection,
    userModulesCollection,
    userQuizesCollection,
    Timestamp,
    GeoPoint,
    updateUser,
};
